// src/components/service-post-builder.js

import React from "react"
import TwoColumnImageRight from "../layouts/TwoColumnImageRight"
import AccentedFullWidth from "../layouts/AccentedFullWidth"
import AccentedTwoColumns from "../layouts/AccentedTwoColumns"
import FullWidthImage from "../layouts/FullWidthImage"
import TwoColumnImageLeft from "../layouts/TwoColumnImageLeft"
import ServiceTestimonial from "../layouts/ServiceTestimonial"
import AccentedImageRight from "../layouts/AccentedImageRight"
import OurWorkLink from "../layouts/OurWorkLink"

const ServicePostBuilder = ({ layoutData }) => {

  const layoutType = layoutData.fieldGroupName

  /**
   * Default component
   */
  const Default = () => <div>In AllLayouts the mapping of this component is missing: {layoutType}</div>

  /**
   * Mapping the fieldGroupName(s) to our components
   */
  const layouts = {
    OurService_Ourservicescontent_ServiceBuilder_TwoColumnImageRight: TwoColumnImageRight,
    OurService_Ourservicescontent_ServiceBuilder_AccentedFullWidth: AccentedFullWidth,
    OurService_Ourservicescontent_ServiceBuilder_AccentedTwoColumns: AccentedTwoColumns,
    OurService_Ourservicescontent_ServiceBuilder_FullWidthImage: FullWidthImage,
    OurService_Ourservicescontent_ServiceBuilder_TwoColumnImageLeft: TwoColumnImageLeft,
    OurService_Ourservicescontent_ServiceBuilder_ServiceTestimonial: ServiceTestimonial,
    OurService_Ourservicescontent_ServiceBuilder_AccentedImageRight: AccentedImageRight,
    OurService_Ourservicescontent_ServiceBuilder_OurWorkLink: OurWorkLink,
    page_default: Default
  }

  /**
   * If layout type is not existing in our mapping, it shows our Default instead.
   */
  const ComponentTag = layouts[layoutType] ? layouts[layoutType] : layouts['page_default']

  return (
    <ComponentTag {...layoutData} />
  )
}

export default ServicePostBuilder