// src/layouts/TwoColumnImageRight/TwoColumnImageRight.js

import React from "react"
import styled from 'styled-components'
import Img from "gatsby-image"

const TwoColumnImageRight = ({ sOneLeftContent, sOneRightImage }) => {

  return (
    <BlockContent>
        <div 
        data-sal="slide-right"
        data-sal-duration="1000"
        data-sal-delay="300"
        data-sal-easing="ease"
        dangerouslySetInnerHTML={{__html: sOneLeftContent}} />
        <div
        data-sal="slide-left"
        data-sal-duration="1000"
        data-sal-delay="600"
        data-sal-easing="ease"
        >
          <Img fluid={sOneRightImage.localFile.childImageSharp.fluid} alt={sOneRightImage.title} />
        </div>
    </BlockContent>
  )
}

const BlockContent = styled.section`
    max-width: 1190px;
    width: 100%;
    margin: 50px auto;
    padding: 0px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    overflow: hidden;
    > div {
      width: calc(50% - 25px);
    }
    h2 {
        font-family: "Nobel",sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 65px;
        line-height: 75px;
        color: #2b2520;
        margin-top: 0;
    }
    h3 {
      font-family: "Nobel",sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 50px;
      line-height: 60px;
      color: #2b2520;
      margin-top: 0;
    }
    h4 {
      font-family: "Nobel",sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 40px;
      line-height: 48px;
      color: #2b2520;
      margin-top: 0;
    }
    p {
        font-family: "Work Sans";
        font-size: 18px;
        font-weight: 400;
        line-height: 38px;
        color: #777c7c;
        margin-bottom: 0;
    }
    @media(max-width:1000px) {
      max-width: 700px;
      padding: 20px 50px;
      flex-wrap: wrap;
      > div {
        width: 100% ;
        &:first-child {
          margin-bottom: 20px;
      }
        &:last-child {
            padding-right: 0px;
        }
      }
      h2 {
          font-size: 44px;
          line-height: 1.3;
          margin-bottom: 10px;
          padding-left: 0;
      }
      h3 {
          font-size: 36px;
          line-height: 1.3;
          margin-bottom: 10px;
      }
      h4 {
          font-size: 28px;
          line-height: 1.3;
          margin-bottom: 10px;
      }
      p {
        padding-left: 0;
      }
  }
  @media(max-width:767px) {
      max-width: 500px;
      padding: 0px;
      margin: 20px auto;
      h2 {
          font-size: 36px;
      }
      h3 {
          font-size: 28px;
      }
      h4 {
          font-size: 20px;
      }
      p {
          font-size: 16px;
          line-height: 1.6;
      }
  }
`

export default TwoColumnImageRight