// src/layouts/AccentedFullWidth/AccentedFullWidth.js

import React from "react"
import styled from 'styled-components'

const AccentedFullWidth = ({ sTwoFullWidthContent }) => {

  return (
    <BlockContent>
        <div 
        data-sal="slide-up"
        data-sal-duration="1000"
        data-sal-delay="300"
        data-sal-easing="ease"
        dangerouslySetInnerHTML={{__html: sTwoFullWidthContent}}  />
    </BlockContent>
  )
}

const BlockContent = styled.section`
    max-width: 1190px;
    width: 100%;
    margin: 0px auto;
    padding: 20px 100px;
    border-left: 10px solid #db4140;
    overflow: hidden;
    h2 {
        font-family: "Nobel",sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 65px;
        line-height: 75px;
        color: #2b2520;
        margin-top: 0;
    }
    h3 {
        font-family: "Nobel",sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 50px;
        line-height: 60px;
        color: #2b2520;
        margin-top: 0;
    }
    h4 {
        font-family: "Nobel",sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 40px;
        line-height: 48px;
        color: #2b2520;
        margin-top: 0;
    }
    p {
        font-family: "Work Sans";
        font-size: 18px;
        font-weight: 400;
        line-height: 38px;
        color: #777c7c;
        margin-bottom: 0px;
    }
    @media(max-width:1000px) {
        max-width: 700px;
        padding: 20px 50px;
        h2 {
            font-size: 44px;
            line-height: 1.3;
            margin-bottom: 10px;
        }
        h3 {
            font-size: 36px;
            line-height: 1.3;
            margin-bottom: 10px;
        }
        h4 {
            font-size: 28px;
            line-height: 1.3;
            margin-bottom: 10px;
        }
    }
    @media(max-width:767px) {
        max-width: 500px;
        padding: 20px 0;
        border-left: none;
        h2 {
            font-size: 36px;
        }
        h3 {
            font-size: 28px;
        }
        h4 {
            font-size: 20px;
        }
        p {
            font-size: 16px;
            line-height: 1.6;
        }
    }
`

export default AccentedFullWidth