import React from "react"
import { Link } from 'gatsby'
import styled from 'styled-components'

import SmallArrow from '../svgs/small-arrow.svg'

const MoreServices = () => {

    return(
        <ServicesSection>
            <h3>More Services</h3>
            <BoxLinks>
                <Link to="/services/strategy/">Strategy<div><SmallArrow/></div></Link>
                <Link to="/services/marketing-performance">Marketing & Performance<div><SmallArrow/></div></Link>
                <Link to="/services/design-development">Design & Development<div><SmallArrow/></div></Link>
                <Link to="/services/custom-applications">Custom Applications<div><SmallArrow/></div></Link>
                <Link to="/services/experience-design">Experience & Design<div><SmallArrow/></div></Link>
                <Link to="/services/intelligence">Intelligence<div><SmallArrow/></div></Link>
            </BoxLinks>
        </ServicesSection>
    );

}

const ServicesSection = styled.section`
    max-width: 1190px;
    width: 100%;
    margin: 100px auto;
    h3 {
        display: block;
        margin: 0;
        margin-left: auto;
        margin-bottom: 60px;
        font-family: "Nobel",sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 20px;
        line-height: 50px;
        background-color: #2b2520;
        color: #fff;
        width: 270px;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 2px;
    }
    @media(max-width:1000px) {
        max-width: 700px;
        h3 {
            margin-right: auto;
        }
      }
      @media(max-width:767px) {
        max-width: 500px;
        h3 {
            width: 100%;
        }
      }
`

const BoxLinks = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    a {
        font-family: "Nobel",sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 40px;
        line-height: 48px;
        color: #2b2520;
        text-decoration: none;
        display: inline-block;
        padding: 45px;
        padding-right: 60px;
        border: 8px solid #db4140;
        position: relative;
        margin-left: 30px;
        margin-bottom: 30px;
        transition-duration: .3s;
        > div {
            position: absolute;
            bottom: -8px;
            right: -8px;
            width: 48px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #2b2520;
            transition: all .3s cubic-bezier(.77,0,.175,1);
            svg {
                width: 24px;
                color: #fff;
                path {
                    fill: #fff;
                }
            }
        }
        &:hover {
            color: #fff !important;
            background-color: #db4140;
            > div {
                transform: translateX(8px);
            }
        }
    }
    @media(max-width:1000px) {
        a {
            width: 100%;
            margin-left: 0px;
        }
      }
      @media(max-width:767px) {
        a {
            padding: 30px;
            font-size: 28px;
            line-height: 1.2;
        }
      }
`

export default MoreServices