import React from "react"
import styled from 'styled-components'

import Layout from "../components/layout-v2"
import SEO from "../components/seo"
import ServicePostBuilder from "../components/service-post-builder"
import MoreServices from "../components/more-services"
import ContactUs from "../components/contact-us"

const ServicePostTemplate = ({ data: { servicePost } }) =>  {

    const layouts = servicePost.OurServicesContent.serviceBuilder;

    return (
        <Layout>
          <SEO 
          title={servicePost.SEOmeta.metaTitle} 
          description={servicePost.SEOmeta.metaDescription}
          keywords={servicePost.SEOmeta.metaKeywords}
          ogTitle={servicePost.SEOmeta.ogTitle} 
          ogDescription={servicePost.SEOmeta.ogDescription}
          ogImage={servicePost.SEOmeta.ogImage.localFile.childImageSharp.fluid}
          twitterTitle={servicePost.SEOmeta.twitterTitle} 
          twitterDescription={servicePost.SEOmeta.twitterDescription}
          twitterImage={servicePost.SEOmeta.twitterImage.localFile.childImageSharp.fluid}
          />
            <article style={{padding: '0 20px', overflow: 'hidden'}}>
                <TitleSection>
                    <p>Services</p>
                    <h1>{servicePost.title}</h1>
                </TitleSection>
                <div>
                    {   
                        layouts.map((layout, index) => {
                            return <ServicePostBuilder key={index} layoutData={layout} />
                        })
                    }
                </div>
                <MoreServices />
                <ContactUs />
            </article>
        </Layout>
    )

}

const TitleSection = styled.header`
    max-width: 1320px;
    width: 100%;
    padding: 80px 70px;
    margin: 0 auto; 
    margin-top: 20px;
    margin-bottom: 120px;
    border: 10px solid #db4140;
    h1 {
        font-size: 100px;
        margin-top: 0;
        margin-bottom: 0;
    }
    p {
        font-family: "Nobel",sans-serif;
        font-size: 30px;
        line-height: 36px;
        letter-spacing: 2px;
        font-weight: 700;
        color: #db4140;
        text-transform: uppercase;
        margin-bottom: 0;
    }
    @media(max-width:1000px) {
      max-width: 700px;
      padding: 45px;
      h1 {
        font-size: 60px;
      }
      p {
        font-size: 24px;
      }
  }
  @media(max-width: 767px) {
      max-width: 500px;
      padding: 20px;
      margin-bottom: 20px;
      border: 5px solid #db4140;
      h1 {
        font-size: 44px;
      }
      p {
        font-size: 18px;
        line-height: 1.4;
      }
  }
`

export default ServicePostTemplate

export const pageQuery = graphql`
  query ServicePostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current work post by id
    servicePost: wpOurService(id: { eq: $id }) {
      id
      content
      title
      date(formatString: "MMMM DD, YYYY")
      SEOmeta {
        metaTitle
        metaDescription
        metaKeywords
        ogTitle
        ogDescription
        ogImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1320, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1320, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      OurServicesContent {
        serviceBuilder {
            __typename
            ... on WpOurService_Ourservicescontent_ServiceBuilder_TwoColumnImageRight {
                fieldGroupName
                sOneLeftContent
                sOneRightImage {
                  title
                  localFile {
                    childImageSharp {
                        fluid(maxWidth: 800, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                  }
                }
              }
              ... on WpOurService_Ourservicescontent_ServiceBuilder_AccentedFullWidth {
                fieldGroupName
                sTwoFullWidthContent
              }
              ... on WpOurService_Ourservicescontent_ServiceBuilder_AccentedTwoColumns {
                fieldGroupName
                sThreeLeftContent
                sThreeRightContent
              }
              ... on WpOurService_Ourservicescontent_ServiceBuilder_TwoColumnImageLeft {
                fieldGroupName
                sFourRightContent
                sFourLeftImage {
                  title
                  localFile {
                    childImageSharp {
                        fluid(maxWidth: 800, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                  }
                }
              }
              ... on WpOurService_Ourservicescontent_ServiceBuilder_AccentedImageRight {
                fieldGroupName
                sFiveLeftContent
                sFiveRightImage {
                  title
                  localFile {
                    childImageSharp {
                        fluid(maxWidth: 800, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                  }
                }
              }
              ... on WpOurService_Ourservicescontent_ServiceBuilder_ServiceTestimonial {
                fieldGroupName
                sSixAuthorInfo
                sSixServiceAuthor
                sSixServiceQuote
              }
              ... on WpOurService_Ourservicescontent_ServiceBuilder_OurWorkLink {
                fieldGroupName
                sSevenProjectLink
                sSevenProjectTitle
                sSevenProjectImage {
                  title
                  localFile {
                    childImageSharp {
                        fluid(maxWidth: 800, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                  }
                }
              }
              ... on WpOurService_Ourservicescontent_ServiceBuilder_FullWidthImage {
                fieldGroupName
                sEightAccented
                sEightFullImage {
                  title
                  localFile {
                    childImageSharp {
                        fluid(maxWidth: 1300, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                  }
                }
              }
        }
      }
    }
  }
`