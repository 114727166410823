// src/layouts/OurWorkLink/OurWorkLink.js

import React from "react"
import { Link } from "gatsby"
import styled from 'styled-components'
import Img from "gatsby-image"
import { BsArrowRight } from 'react-icons/bs'

const OurWorkLink = ({ sSevenProjectTitle, sSevenProjectLink, sSevenProjectImage }) => {

  return (
    <BlockContent>
        <div 
        data-sal="slide-up"
        data-sal-duration="1000"
        data-sal-delay="300"
        data-sal-easing="ease"
        class="inner-flex">
            <h3>Our Work</h3>
            <div class="left-col">
                <h2 dangerouslySetInnerHTML={{__html: sSevenProjectTitle}} />
                <p><Link to={sSevenProjectLink}>See the Work <BsArrowRight size={24}/></Link></p>
            </div>
            <div class="right-col">
                <Link to={sSevenProjectLink}>
                    <Img fluid={sSevenProjectImage.localFile.childImageSharp.fluid} alt={sSevenProjectImage.title} />
                </Link>
            </div>
        </div>
    </BlockContent>
  )
}

const BlockContent = styled.section`
    background-color: #2b2520;
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
    margin-top: 100px;
    margin-bottom: 100px;
    padding: 0 40px;
    .inner-flex {
        max-width: 1320px;
        width: 100%;
        margin: 0 auto;
        padding: 0;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        h3 {
            position: absolute;
            top: -25px;
            left: 0;
            margin: 0;
            font-family: "Nobel",sans-serif;
            font-weight: 700;
            font-style: normal;
            font-size: 20px;
            line-height: 50px;
            background-color: #db4140;
            color: #fff;
            width: 206px;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 2px;
        }
        .left-col {
            max-width: 550px;
            width: 41.67%;
            h2 {
                font-family: "Nobel",sans-serif;
                font-weight: 700;
                font-style: normal;
                font-size: 50px;
                line-height: 60px;
                color: #fff;
            }
            p {
                a {
                    font-family: "Work Sans";
                    font-size: 20px;
                    line-height: 24px;
                    font-weight: 700;
                    color: #db4140;
                    margin-top: 0;
                    margin-bottom: 0px;
                    text-decoration: none;
                    width: 168px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    svg {
                        color: #db4140;
                        margin-left: 10px;
                        transition: all .3s cubic-bezier(.77,0,.175,1);
                      }
                      &:hover {
                        color: #db4140 !important;
                        svg {
                          transform: translateX(10px);
                        }
                    }
                }
            }
        }
        .right-col {
            max-width: 770px;
            width: 58.33%;
            height: 100%;
            min-height: 475px;
            .gatsby-image-wrapper {
                min-height: 475px;
            }
        }
    }
    @media(max-width:1000px) {
        .inner-flex {
            max-width: 700px;
            .left-col {
                h2 {
                    font-size: 40px;
                    line-height: 1.2;
                    padding-right: 20px;
                }
            }
        }
    }
    @media(max-width:767px) {
        .inner-flex {
            max-width: 500px;
            flex-wrap: wrap;
            padding-bottom: 40px;
            h3 {
                left: calc(50% - 103px);
            }
            .left-col {
                width: 100%;
                max-width: 100%;
                h2 {
                    font-size: 28px;
                }
            }
            .right-col {
                width: 100%;
                max-width: 100%;
                min-height: 200px;
                .gatsby-image-wrapper {
                    min-height: 200px;
                }
            }
        }
    }
`

export default OurWorkLink