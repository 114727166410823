// src/layouts/FullWidthImage/FullWidthImage.js

import React from "react"
import styled from 'styled-components'
import Img from "gatsby-image"

const FullWidthImage = ({ sEightAccented, sEightFullImage }) => {

  return (
    <BlockContent className={sEightAccented === true ? "accented" : ""}>
      <div
      data-sal="slide-up"
      data-sal-duration="1000"
      data-sal-delay="300"
      data-sal-easing="ease"
      >
        <Img fluid={sEightFullImage.localFile.childImageSharp.fluid} alt={sEightFullImage.title} />
      </div>
    </BlockContent>
  )
}

const BlockContent = styled.section`
    max-width: 1190px;
    width: 100%;
    margin: 70px auto;
    padding: 0;
    &.accented {
        margin: 0px auto;
        padding: 50px 100px;
        border-left: 10px solid #db4140;
    }
    @media(max-width:1000px) {
      max-width: 700px;
    }
    @media(max-width:767px) {
      max-width: 500px;
      padding: 0;
      margin-top: 20px;
      margin-bottom: 20px;
      &.accented {
        border-left: none;
        padding: 0;
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
`

export default FullWidthImage